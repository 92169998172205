<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true">
				<span>
					<el-form-item label="时间">
						<el-date-picker v-model="time" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
					</el-form-item>
					<el-form-item label="收货人姓名">						
						<el-input v-model="user_name" placeholder="收货人姓名" class="w120"></el-input>
					</el-form-item>
					<el-form-item label="手机号码">						
						<el-input v-model="user_mobile" placeholder="手机号码" class="w120"></el-input>
					</el-form-item>
					<el-form-item label="订单状态">
						<el-select v-model="order_status" placeholder="订单状态" clearable class="w120">
							<el-option v-for="item in orderStatusList" :key="item.val" :label="item.txt" :value="item.val"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="支付方式">						
						<el-select v-model="pay_type" placeholder="支付方式" clearable class="w120">
							<el-option v-for="item in payTypeList" :key="item.val" :label="item.txt" :value="item.val"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="发货状态">						
						<el-select v-model="send_status" placeholder="发货状态" clearable class="w120">
							<el-option v-for="item in sendStatusList" :key="item.val" :label="item.txt" :value="item.val"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
				</span>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<!-- <el-table-column prop="id" label="ID" width="50" align="center">
			</el-table-column> -->
			<el-table-column prop="pay_time" label="付款时间" width="140">
			</el-table-column>
			<el-table-column prop="true_user_name" label="收货人姓名" width="110">
			</el-table-column>
			<el-table-column prop="user_mobile" label="电话号码" width="110">
			</el-table-column>
			<el-table-column prop="quantity" label="数量/金额" width="100">
				<template slot-scope="scope">{{scope.row.quantity}}台,{{scope.row.order_price}}</template>
			</el-table-column>
			<!-- <el-table-column prop="order_price" label="支付金额" width="80" align="center">
			</el-table-column> -->
			<el-table-column prop="pay_type_text" label="支付方式" width="80" align="center">
			</el-table-column>
			<el-table-column prop="order_status_text" label="支付状态" width="100" align="center">
			</el-table-column>
			<el-table-column prop="send_status_text" label="发货状态" width="100" align="center">
			</el-table-column>
			<el-table-column prop="user_addr" label="收货地址">
			</el-table-column>
			<el-table-column prop="remark_data" label="用户留言">
			</el-table-column>
			<el-table-column label="物流信息" >
				<template slot-scope="scope">
					<template v-if="scope.row.logistics_name">{{scope.row.logistics_name}}，单号：{{scope.row.logistics_no}}</template>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="setEditor(scope.row.order_no)">修改订单</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>
		<el-dialog title="订单信息" :visible.sync="dialogShow" width="700px" center>
			<el-form :model="orderInfo" label-width="130px">
				<el-form-item label="收货人姓名">
					<el-input class="w2" v-model="orderInfo.true_user_name" placeholder="收款人姓名"></el-input>
				</el-form-item>
				<el-form-item label="联系电话">
					<el-input class="w2" v-model="orderInfo.user_mobile" placeholder="联系电话"></el-input>
				</el-form-item>
				<el-form-item label="收货地址">
					<el-input v-model="orderInfo.user_addr" placeholder="收货地址"></el-input>
				</el-form-item>
				<el-form-item label="购买数量和金额">
					数量 {{orderInfo.quantity}}台，共 {{orderInfo.order_price}}元，
				</el-form-item>
				<el-form-item label="发货状态">
					<el-select v-model="orderInfo.send_status" placeholder="发货状态" clearable>
						<el-option v-for="item in sendStatusList" :key="item.val" :label="item.txt" :value="item.val"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="快递信息" v-if="orderInfo.send_status > 1">
					<el-input class="w2 mr10" v-model="orderInfo.logistics_name" placeholder="快递公司"></el-input>
					<el-input class="w2" v-model="orderInfo.logistics_no" placeholder="运单号"></el-input>
				</el-form-item>
				<el-form-item label="订单状态">
					<el-select v-model="orderInfo.order_status" placeholder="订单状态" clearable>
						<el-option v-for="item in orderStatusList" :key="item.val" :label="item.txt" :value="item.val"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submit">确 认</el-button>
				<el-button @click="hideDialog">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import { getOrderList, getOrderInfo, updateOrder} from '../../api/api';
	export default {
		data() {
			return {
				time:[],
				user_name: '',
				user_mobile: '',
				order_status: '',
				pay_type: '',
				send_status: '',
				orderInfo: {
				},
				orderStatusList:[
					{ txt: '待支付', val:0 },
					{ txt: '已支付', val:1 },
					{ txt: '订单退款', val:2 },
					{ txt: '订单取消', val:3 },
				],
				payTypeList:[
					{ txt: '支付宝', val:'alipay' },
					{ txt: '微信', val:'weixin' },
				],
				sendStatusList:[
					{ txt: '未付款', val:0 },
					{ txt: '待发货', val:1 },
					{ txt: '已发货', val:2 },
					{ txt: '已完成', val:3 },
					{ txt: '退货中', val:4 },
					{ txt: '已退货', val:5 }
				],
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				dialogShow: false
			}
		},		
		created() {
			//获取列表
			this.getList(1);
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取列表
			getList(val) {
                if(val == '0') this.page = 1
				const para = {
					page: this.page,
					pagesize: this.pagesize,
					user_name: this.user_name,
					user_mobile: this.user_mobile,
					start_date: this.time[0],
					end_date: this.time[1],
					order_status: this.order_status,
					pay_type: this.pay_type,
					send_status: this.send_status
				};
				this.listLoading = true;
				getOrderList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.list;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//显示编辑界面
			setEditor(order_no) {
				let para = {
					order_no:order_no
				};
				getOrderInfo(para).then((res) => {
					if(res.code == 1){
						this.orderInfo = res.data
						this.dialogShow = true
					}					
				});
			},
			hideDialog(){
				this.orderInfo = {}
				this.dialogShow = false
			},
			submit(){				
				updateOrder(this.orderInfo).then((res) => {
					if(res.code == 1){
						this.getList();
						this.hideDialog()
					}					
				});
			}
		},
	}
</script>
